import React, { type MouseEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '../../components/buttons/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useRootStore } from 'app/mobxStore';
import TextField from '@mui/material/TextField';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CARE_TEAM_ROLES, getFirstCareTeamRole, ROLE_TITLES, ROLES } from 'app/consts';
import apiService, { lite } from '../../services/apiService/apiService';
import AmplService from 'app/services/amplService/amplService';
import type { IRoleData } from '../../mobxStore/types';
import { toast } from 'react-toastify';
import { ReactComponent as PenguinProfile } from 'app/assets/penguins/penguin_profile_placeholder.svg';
import PencilIcon from '../../assets/icons/Pencil_icon';
import variables from '../../utils/variables.module.scss';

interface IProps {
  closeOnboarding: () => void;
}

const getDefaultDisplayName = (nickName: string, email: string): string => {
  if (email.split('@')[0] === nickName) {
    return '';
  }
  return nickName;
};

const ProfileScreen = (props: IProps): React.JSX.Element => {
  const { closeOnboarding } = props;
  const { userStore, metaDataStore } = useRootStore();
  const { t } = useTranslation();
  const defaultDisplayName = getDefaultDisplayName(
    userStore.loggedInUser.data.nickName,
    userStore.loggedInUser.data.email
  );
  const [displayName, setDisplayName] = useState(defaultDisplayName);
  const [displayNameError, setDisplayNameError] = useState('');

  const isNameTaken = (name: string): boolean => {
    if (name === userStore.loggedInUser.data.nickName) {
      return false;
    }
    return metaDataStore.users.some(u => u.nickName === name);
  };

  useEffect(() => {
    validateDisplayName(displayName);
  }, [displayName]);

  const validateDisplayName = (name: string): void => {
    if (isNameTaken(name)) {
      setDisplayNameError('validateDisplayNameExists');
      return;
    }
    setDisplayNameError('');
  };

  const roles = CARE_TEAM_ROLES;
  const initRole = getFirstCareTeamRole(userStore.loggedInUser.data.roles);
  const [selectedRole, setSelectedRole] = useState<ROLES | undefined>(
    initRole === ROLES.OTHER ? undefined : initRole
  );
  const handleSelectRole = (event: SelectChangeEvent<typeof selectedRole>): void => {
    const {
      target: { value }
    } = event;
    setSelectedRole(value as ROLES);
  };

  const onDoneClick = async (e: MouseEvent): Promise<void> => {
    const followAll = ![ROLES.ATTENDING, ROLES.VENDOR_REP].includes(selectedRole as ROLES);

    const getReminderDays = (): string[] | null => {
      const setReminderDays =
        lite &&
        selectedRole === ROLES.ATTENDING &&
        userStore.loggedInUser.data.reminderDays === null;
      if (setReminderDays) {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'];
      }
      return userStore.loggedInUser.data.reminderDays;
    };

    const reminderDays = getReminderDays();
    const data = {
      nickName: displayName,
      followAll,
      reminderDays
    };
    await apiService.updateCurrentUser(data);
    await apiService.updateCurrentUserRole(initRole as ROLES, selectedRole as ROLES);

    let newRoles = userStore.loggedInUser.data.roles.map(role => {
      if (role.role === initRole) {
        const newRole: IRoleData = { role: selectedRole as ROLES };
        return newRole;
      }
      return role;
    });
    if (selectedRole === ROLES.VENDOR_REP) {
      await apiService.deleteCurrentUserRole(ROLES.INVITER);
      newRoles = newRoles.filter(role => role.role !== ROLES.INVITER);
    }

    userStore.loggedInUser.setData({
      ...userStore.loggedInUser.data,
      nickName: displayName,
      roles: newRoles,
      followAll,
      reminderDays
    });

    metaDataStore.updateCurrentUserRole(initRole as ROLES, selectedRole as ROLES);

    closeOnboarding();
  };

  const handleClickUploadPhoto = async (e: MouseEvent): Promise<void> => {
    toast.success(t('comingSoon'));
    AmplService.sendEvent(AmplService.EVENTS.ONBOARDING_UPLOAD_PHOTO_CLICKED);
  };

  return (
    <div className="profile-screen">
      <div className="screen-content header">
        <div className="title">
          <Trans i18nKey="onboardingProfileTitle" />
        </div>
        <div className="text">
          <Trans i18nKey="onboardingProfileText" />
        </div>
      </div>
      <div className="screen-content main">
        <div className="profile-picture">
          <div className="profile-container">
            <Button
              classNames="btn upload-photo-button"
              buttonContent={<PenguinProfile className="penguin-placeholder" />}
              onClick={handleClickUploadPhoto}
            />
            <Button
              classNames="edit-icon-button"
              buttonContent={<PencilIcon fill={variables.primaryPurple} className="edit-icon" />}
              onClick={handleClickUploadPhoto}
            />
          </div>
          <Button
            classNames="btn outlined upload-photo-button-secondary"
            buttonText="uploadPhoto"
            onClick={handleClickUploadPhoto}
          />
        </div>
        <div className="profile-fields">
          <div className="text-field-container">
            <div className="error-message">{t(displayNameError)}</div>
            <div className="profile-field-label">{t('nickName')}</div>
            <TextField
              placeholder={t('nickNamePlaceholder') ?? ''}
              className="text-field"
              type="text"
              value={displayName}
              onChange={e => {
                setDisplayName(e.target.value);
              }}
              onFocus={e => {
                setTimeout(() => {
                  e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
                }, 0);
              }}
              onBlur={() => {
                setTimeout(() => {
                  if (displayNameError !== '') {
                    setDisplayName(userStore.loggedInUser.data.nickName);
                    setDisplayNameError('');
                    return;
                  }
                  void userStore.loggedInUser.setNickNameToServer(displayName);
                  userStore.loggedInUser.setNickNameToStore(displayName);
                });
              }}
            />
          </div>
          <div className="dropdown-select-container">
            <div className="profile-field-label">{t('role')}</div>
            <Select
              variant="standard"
              className={clsx('dropdown-select', { placeholder: selectedRole === undefined })}
              disableUnderline
              displayEmpty
              value={selectedRole}
              onChange={handleSelectRole}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 225, boxShadow: '0 -2px 15px 3px rgb(0, 0, 0, 0.2)' }
                },
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                }
              }}
            >
              <MenuItem disabled value="">
                Select role
              </MenuItem>
              {roles.map(role => {
                return (
                  <MenuItem key={role} value={role}>
                    {ROLE_TITLES[role]?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <div className="actions">
        <Button
          classNames="btn primary save-profile-button"
          disabled={selectedRole === undefined || displayName === '' || displayNameError !== ''}
          buttonText="getStarted"
          onClick={onDoneClick}
        />
      </div>
    </div>
  );
};
export default ProfileScreen;
